<template>
    <div class="columns is-mobile is-centered">
        <div class="column is-half">
            <sign-up v-if="formState === 'signUp'"></sign-up>
            <sign-in v-if="formState === 'signIn'"></sign-in>
            <forgot-password v-if="formState === 'forgotPassword'"></forgot-password>
            <br/>

            <!-- アカウント登録画面の場合 -->
            <div v-if="formState === 'signUp'">
                <p class="button is-text" v-on:click="toSignIn">既にアカウントをお持ちの方(ログイン)</p>
                <p class="button is-text" v-on:click="toforgotPassword">パスワードを忘れた方</p>
            </div>

            <!-- ログイン画面の場合 -->
            <div v-if="formState === 'signIn'">
                <p class="button is-text" v-on:click="toSignUp">アカウントをお持ちでない方</p>
                <p class="button is-text" v-on:click="toforgotPassword">パスワードを忘れた方</p>
            </div>

            <!-- パスワード忘れ画面の場合 -->
            <div v-if="formState === 'forgotPassword'">
                <p class="button is-text" v-on:click="toSignUp">アカウントをお持ちでない方</p>
                <p class="button is-text" v-on:click="toSignIn">既にアカウントをお持ちの方(ログイン)</p>
            </div>
        </div>
    </div>
</template>

<script>
    import SignUp from "./SignUp";
    import SignIn from "./SignIn";
    import ForgotPassword from "./ForgotPassword";

    export default {
        name: "auth",
        data() {
            return {
                formState: "signIn"
            };
        },
        methods: {
            toSignUp() {
                this.formState = "signUp";
            },
            toSignIn() {
                this.formState = "signIn";
            },
            toforgotPassword() {
                this.formState = "forgotPassword";
            }
        },
        components: {
            SignUp,
            SignIn,
            ForgotPassword
        }
    };
</script>
