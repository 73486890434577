import Vue from 'vue'
import Router from 'vue-router'
// import Home from './views/Home.vue'
import NotFound from './views/NotFound.vue'
import {Auth} from 'aws-amplify'
import AuthComponent from './components/Auth'
import MyPage from './components/MyPage'
import Download from './components/Download'
// import Profile from './components/Profile'
Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {path: '/', component: MyPage},
        {path: '/auth', component: AuthComponent},
        {path: '/mypage', component: MyPage, meta: {requiresAuth: true}},
        {
            path: '/download',
            component: Download,
            meta: {requiresAuth: true},
            props: (route) => ({filename: route.query.filename})
        },
        // for develop
        // { path: '/profile', component: Profile, meta: { requiresAuth: true} },
        // Error Page
        {path: '*', component: NotFound}
    ]
});

router.beforeResolve((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        Auth.currentAuthenticatedUser().catch(() => {
            console.log(to.fullPath);
            next({
                path: '/auth',
                query: {
                    redirect: to.fullPath,
                }
            });
        });
    }
    next()
});

export default router