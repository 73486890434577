import Vue from 'vue'
import App from './App.vue'
import router from './router'
// Buefy
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
// VeeValidate
import VeeValidate, {Validator} from 'vee-validate'
import ja from 'vee-validate/dist/locale/ja'
// Amplify
import Amplify, * as AmplifyModules from 'aws-amplify'
import {AmplifyPlugin} from 'aws-amplify-vue'

Vue.use(Buefy);

Vue.use(VeeValidate);
Validator.localize('ja', ja);

const REGION = process.env.VUE_APP_AWS_REGION;
Amplify.configure({
    Auth: {
        identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID,
        userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.VUE_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
        region: REGION,
        mandatorySignIn: true,
    },
    Storage: {
        bucket: process.env.VUE_APP_S3_DATA_BUCKET,
        region: REGION,
    },
});
Vue.use(AmplifyPlugin, AmplifyModules);

Vue.config.productionTip = false;
new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
