<template>
    <div id="sign-up">
        <div class="notification is-danger" v-if="err_response">{{ err_response }}</div>
        <div class="notification is-success" v-if="info_message">{{ info_message }}</div>

        <div v-show="formState === 'signUp'">
            <h1 class="title">アカウント登録</h1>

            <!-- email -->
            <b-field
                    :message="errors.first('username')"
                    :type="{'is-danger': errors.has('username')}"
                    label="Email"
            >
                <b-input
                        data-vv-as="Email"
                        icon="email-outline"
                        name="username"
                        type="text"
                        v-model="form.username"
                        v-validate="'required|email'"
                ></b-input>
            </b-field>

            <!-- password -->
            <b-field
                    :message="errors.first('password')"
                    :type="{'is-danger': errors.has('password')}"
                    label="Password"
            >
                <b-input
                        data-vv-as="Password"
                        icon="lock"
                        name="password"
                        type="password"
                        v-model="form.password"
                        v-validate="'required|alpha_num|min:8'"
                ></b-input>
            </b-field>

            <div class="field">
                <div class="control">
                    <button
                            :disabled="errors.any() || !form.username || !form.password"
                            class="button is-success"
                            v-on:click="signUp"
                    >regist
                    </button>
                    <p style="font-size: 0.8rem;padding-top: 5px;">パスワードは半角英数字、大文字、小文字を 1 つ以上含み、8 文字以上で指定してください。</p>
                </div>
            </div>
        </div>

        <div v-show="formState === 'confirmSignUp'">
            <h1 class="title">検証コード入力</h1>

            <!-- authCode -->
            <b-field
                    :message="errors.first('authCode')"
                    :type="{'is-danger': errors.has('authCode')}"
                    label="検証コード"
            >
                <b-input
                        data-vv-as="検証コード"
                        data-vv-delay="100"
                        name="authCode"
                        type="text"
                        v-model="form.authCode"
                        v-validate="'required|numeric|min:6|max:6'"
                ></b-input>
            </b-field>

            <div class="field">
                <div class="control">
                    <button
                            :disabled="errors.any()"
                            class="button is-success"
                            v-on:click="confirmSignUp"
                    >confirm
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Auth} from "aws-amplify";
    import {AmplifyEventBus} from "aws-amplify-vue";

    export default {
        name: "sign-up",
        data() {
            return {
                formState: "signUp",
                form: {
                    username: "",
                    password: "",
                    authCode: ""
                },
                err_response: "",
                info_message: ""
            };
        },
        methods: {
            async signUp() {
                const {username, password} = this.form;
                Auth.signUp(username, password)
                    .then(() => {
                        this.formState = "confirmSignUp";
                        this.err_response = null;
                        this.info_message =
                            "メールアドレスに送信された、検証コードを入力してください。";
                    })
                    .catch(err => {
                        if (err.code === "InvalidPasswordException") {
                            // "Password does not conform to policy: Password must have lowercase characters."
                            // MEMO: VeeValidateの正規表現で完璧にやれれば良いのだが、多分めんどくさいのでサーバ側で
                            this.err_response =
                                "パスワードポリシーを満たしていません。半角英数字、大文字、小文字を一つ以上含み、8文字以上で入力してください。";
                        }
                        if (err.code === "UsernameExistsException") {
                            // "An account with the given email already exists."
                            // MEMO: 入力されたメールアドレスのユーザがこのサービスに存在することがわかってしまうため微妙なのかもしれないが、とりあえず代案が思いつかなかった…
                            this.err_response =
                                "入力されたメールアドレスは既に登録されています。";
                        }
                    });
            },
            async confirmSignUp() {
                const {username, authCode, password} = this.form;
                Auth.confirmSignUp(username, authCode)
                    .then(() => {
                        alert("検証コードの確認が完了しました！"); // TODO: Bulmaのalert dialogを使う方がエレガント
                        // 続けてサインイン
                        Auth.signIn(username, password).then(() => {
                            AmplifyEventBus.$emit("authState", "signedIn");
                            this.$router.push("/mypage");
                        });
                    })
                    .catch(err => {
                        if (err.code === "CodeMismatchException") {
                            // "Invalid verification code provided, please try again."
                            this.err_response =
                                "入力された検証コードが間違っています。再入力をお願いします。";
                        }
                    });
            }
        }
    };
</script>
