<template>
    <div id="mypage">
        <div class="notification is-danger" v-if="err_response">{{ err_response }}</div>
        <div class="notification is-success" v-if="info_message">{{ info_message }}</div>

        <!-- loading -->
        <b-loading :active.sync="loading" :can-cancel="true" :is-full-page="true"/>

        <div class="container">
            <h1 class="title">MyPage :</h1>
            <!-- 検索条件 start -->
            <div class="box has-background-light">
                <h2 class="subtitle">検索条件</h2>
                <div class="field is-horizontal">
                    <div class="field-label is-normal">
                        <label class="label">測定日</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <p class="control">
                                <b-datepicker
                                        icon="calendar-today"
                                        placeholder="from"
                                        v-model="searchCondition.from_start_date"
                                ></b-datepicker>
                            </p>
                        </div>
                        <div class="field">
                            <p class="control">
                                <b-datepicker
                                        icon="calendar-today"
                                        placeholder="to"
                                        v-model="searchCondition.to_start_date"
                                ></b-datepicker>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal">
                    <div class="field-label is-normal">
                        <label class="label">シリアル番号</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <p class="control">
                                <b-input placeholder="計測時のセンサーのシリアル番号" v-model="searchCondition.serial"/>
                            </p>
                        </div>
                        <div class="field-label is-normal">
                            <label class="label">デバイスID</label>
                        </div>
                        <div class="field">
                            <p class="control">
                                <b-input placeholder="計測時のセンサーのデバイスID" v-model="searchCondition.device_id"/>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="level-right">
                    <div class="level-item">
                        <div class="field is-grouped">
                            <button @click="search" class="button field is-info">
                                <b-icon icon="magnify"></b-icon>
                                <span>Search</span>
                            </button>
                            <button
                                    :disabled="!searchCondition"
                                    @click="searchCondition = {}"
                                    class="button field is-dark"
                            >
                                <b-icon icon="close"></b-icon>
                                <span>Clear</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 検索条件  end  -->

            <!-- table start -->
            <div class="box">
                <div class="notification is-warning" v-if="data.length == 0">該当する計測データは見つかりませんでした。</div>
                <p class="notification is-info" v-if="data.length != 0">計測データ数: {{ data.length }}</p>

                <b-table
                        :checked-rows.sync="checkedRows"
                        :data="data"
                        bordered
                        checkable
                        default-sort="start_datetime"
                        default-sort-direction="desc"
                        striped
                        style="margin-bottom: 1.0rem;"
                >
                    <template slot-scope="props">
                        <!--
                           <b-table-column field="_id" label="ID">{{ props.row._id }}</b-table-column>
                        -->

                        <b-table-column
                                field="start_datetime"
                                label="測定開始日時"
                                sortable
                        >{{ props.row.start_datetime }}
                        </b-table-column>
                        <b-table-column field="length" label="計測時間(分)" sortable>{{ props.row.length }}</b-table-column>

                        <b-table-column field="serial" label="シリアル番号" sortable>{{ props.row.serial }}
                        </b-table-column>
                        <b-table-column field="device_id" label="デバイスID" sortable>{{ props.row.device_id }}
                        </b-table-column>
                        <b-table-column field="channels" label="計測チャンネル">{{ props.row.channels }}</b-table-column>
                        <b-table-column field="rate" label="サンプリングレート(Hz)">{{ props.row.rate }}</b-table-column>

                        <b-table-column label width="20">
                            <b-tooltip :label="props.row.filename" position="is-left">
                                <b-icon
                                        @click.native="download(props.row.filename)"
                                        icon="download"
                                        type="is-success"
                                ></b-icon>
                            </b-tooltip>
                            <!-- <b-icon icon="delete" type="is-dark"></b-icon> -->
                        </b-table-column>
                    </template>
                </b-table>
                <article class="message is-danger" v-if="this.DOWNLOAD_MAX <= this.checkedRows.length">
                    <div class="message-body">{{ DOWNLOAD_MAX }}個以上の計測データを一括ダウンロードすることはできません。</div>
                </article>

                <nav class="level">
                    <div class="level-left">
                        <div class="level-item">
                            <button
                                    :disabled="(0 == this.checkedRows.length || this.DOWNLOAD_MAX <= this.checkedRows.length)"
                                    @click="allDownloadChecked"
                                    class="button field is-success"
                            >
                                <b-icon icon="download"></b-icon>
                                <span>Checked Download</span>
                            </button>
                        </div>
                        <div class="level-item">
                            <p v-if="0 < this.checkedRows.length ">選択済みの計測データ: {{ this.checkedRows.length }}</p>
                        </div>
                        <!-- ページングがない場合はあまり必要性がない(全選択2回押しで全選択解除になる)のでコメントアウト
                        <div class="level-item">
                          <button
                            class="button field is-danger"
                            @click="checkedRows = []"
                            :disabled="!checkedRows.length"
                          >
                            <b-icon icon="close"></b-icon>
                            <span>Checked Clear</span>
                          </button>
                        </div>
                        -->
                    </div>
                </nav>
            </div>
            <!-- table  end  -->
        </div>
    </div>
</template>

<script>
import {Auth, Storage} from "aws-amplify";
import axios from "axios";
import dayjs from "dayjs";

export default {
  name: "MyPage",
  data() {
    return {
      err_response: "",
      info_message: "",
      loading: false,
      DOWNLOAD_MAX: 15,
      axios_retry: 0,
      user: {},
      idToken: "",
                // for develop
                // data: [
                //   {
                //     _id: 1,
                //     start_datetime: dayjs("2016-10-15 13:43:27").format(
                //       "YYYY/MM/DD HH:mm:ss"
                //     ),
                //     device_id: "D2:35:53:12:81:A0",
                //     serial: "1810072B",
                //     channels: ["CH1", "CH2", "CH3"],
                //     filename: "download/test00.zip"
                //   },
                //   {
                //     _id: 2,
                //     start_datetime: dayjs("2019-03-20T05:55:36.501+09:00").format(
                //       "YYYY/MM/DD HH:mm:ss"
                //     ),
                //     device_id: "C2:35:53:12:81:A0",
                //     serial: "1910072B",
                //     filename: "download/test01.csv"
                //   }
                // ],
                data: [],

                checkedRows: [],
                searchCondition: {}
            };
        },
        beforeCreate() {
            Auth.currentAuthenticatedUser()
                .then(user => {
                    this.user = user;
                    this.idToken = user.signInUserSession.idToken.jwtToken;
                    this.search();
                })
                .catch(() => console.log("not signed in..."));

            // Add latest auth access token to every http request
            axios.interceptors.request.use(function (config) {
                return Auth.currentSession()
                    .then(session => {
                        // User is logged in. Set auth header on all requests
                        config.headers.Authorization = session.idToken.jwtToken;
                        this.idToken = session.idToken.jwtToken;
                        return Promise.resolve(config);
                    })
                    .catch(() => {
                        // No logged-in user: don't set auth header
                        return Promise.resolve(config);
                    });
            });
        },
        methods: {
            search: function () {
                this.loading = true;

                // console.log("idToken" + this.idToken);
                // const now = dayjs().format("YYYY/MM/DD HH:mm:ss");
                // console.log("search:" + now);
                // console.log("searchCondition: " + JSON.stringify(this.searchCondition));

                // XHR用のparamsを作成
                let params = Object.assign({}, this.searchCondition);
                if (params.from_start_date) {
                    params.from_start_date = dayjs(params.from_start_date).format(
                        "YYYY-MM-DD"
                    );
                }
                if (params.to_start_date) {
                    params.to_start_date = dayjs(params.to_start_date).format("YYYY-MM-DD");
                }

                const endpoint_url =
                    process.env.VUE_APP_REST_API_ENDPOINT + "/measures/search";
                const config = {
                    headers: {
                        Authorization: this.idToken
                    },
                    params: params
                };
                axios
                    .get(endpoint_url, config)
                    .then(response => {
                        // console.log(response.data.hits.hits);
                        // テーブル表示に必要な項目だけに詰め替え(適宜書式変更)
                        const filename_regix = /s3:\/\/(.*)\/private\/(.*?)\//g;
                        this.data = response.data.hits.hits.map(row => ({
                          _id: row._id,
                          start_datetime: dayjs(row._source.start_datetime).format(
                              "YYYY/MM/DD HH:mm:ss"
                          ),
                          length: dayjs(row._source.end_datetime).diff(
                              dayjs(row._source.start_datetime),
                              "minutes"
                          ),
                          device_id: row._source.device_id,
                          serial: row._source.sensor.serial,
                          channels: row._source.sensor.channels.join(','),
                          rate: row._source.rate.toFixed(1),
                          filename:
                              row._source.s3_path.replace(filename_regix, "") + row._source.s3_path.replace(filename_regix, "").slice(0, -1) + '.zip',
                        }));
                        // チェックボックスの選択状態を初期化
                        this.checkedRows = [];
                        this.loading = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.loading = false;
                    });
            },
            download: function (filename) {
                const s3_key = filename;
                Storage.get(s3_key, {
                    level: "private",
                    expires: 10
                }).then(oneTimeUrl => {
                    window.open(oneTimeUrl, "_blank");
                });
            },
            allDownloadChecked: function () {
                const checkedDataList = this.checkedRows.map(row => row.filename);

                const endpoint_url =
                    process.env.VUE_APP_REST_API_ENDPOINT + "/measures/batch_download";
                const data = {
                    files: checkedDataList
                };
                const config = {
                    headers: {
                        Authorization: this.idToken,
                        "Content-Type": "application/json"
                    }
                };

                axios.post(endpoint_url, data, config).then(function () {
                    // TODO: use buefy Dialog

                    alert(
                        "一括ダウンロードを受け付けました。\n処理が完了したらメールアドレスにダウンロード用のURLが送られますので、そちらをご確認ください。"
                    );
                });
            },
            aiRequest: function (filename) {
                const s3_key = filename;
                Storage.get(s3_key, {
                    level: "private",
                    expires: 10
                }).then(oneTimeUrl => {
                    window.open(oneTimeUrl, "_blank");
                });
            },
            allAiRequestChecked: function () {
                const checkedDataList = this.checkedRows.map(row => row.filename);

                const endpoint_url =
                    process.env.VUE_APP_REST_API_ENDPOINT + "/measures/batch_ai_request";
                const data = {
                    files: checkedDataList
                };
                const config = {
                    headers: {
                        Authorization: this.idToken,
                        "Content-Type": "application/json"
                    }
                };

                axios.post(endpoint_url, data, config).then(function () {
                    // TODO: use buefy Dialog

                    alert(
                        "AI解析リクエストを受け付けました。\nメールアドレスにリクエスト内容がが送られますので、そちらをご確認ください。"
                    );
                });
            }
        }
    };
</script>
