<template>
  <div id="app">
    <section class="section has-background-white-bis">
      <!-- header -->
      <nav class="level">
        <p class="level-item level-centered has-text-centered">
          <a v-bind:href="alias_url">
            <img
              src="./assets/logo.svg"
              style="height: 40px"
              v-bind:alt="domain"
            />
          </a>
        </p>
        <p class="level-item level-centered has-text-centered">
          <!--                    <router-link tag="p" to="/">-->
          <!--                        <a class="button is-text">Home</a>-->
          <!--                    </router-link>-->
        </p>
        <p class="level-item level-centered has-text-centered">
          <router-link tag="p" to="/mypage">
            <a class="button is-text">MyPage</a>
          </router-link>
        </p>
        <div v-if="isDevelop">
          <p class="level-item level-centered has-text-centered">
            <router-link tag="p" to="/profile">
              <!-- for develop -->
              <a class="button is-text">(Profile)</a>
            </router-link>
          </p>
        </div>
        <div
          v-if="!signedIn"
          class="level-item level-centered has-text-centered"
        >
          <router-link tag="p" to="/auth">
            <a class="button is-text">Sign Up / Sign In</a>
          </router-link>
        </div>
        <div
          v-if="signedIn"
          class="level-item level-centered has-text-centered"
        >
          <p v-on:click="toSignOut">
            <a class="button is-text">Sign Out</a>
          </p>
        </div>
      </nav>

      <!-- main contents -->
      <div class="box">
        <router-view></router-view>
      </div>

      <!-- footer -->
      <div class="has-text-centered">
        <p class="has-text-grey-darker">© 2018-2021 PGV</p>
      </div>
    </section>
  </div>
</template>

<script>
import { AmplifyEventBus } from "aws-amplify-vue";
import { Auth } from "aws-amplify";

export default {
  name: "app",
  data() {
    return {
      signedIn: false,
      isDevelop: process.env.VUE_APP_ENV === "dev",
      url: process.env.VUE_APP_MEAS_URL,
      alias_url: process.env.VUE_APP_MEAS_ALIAS_URL,
      domain: process.env.VUE_APP_S3_WEBAPP_BUCKET,
    };
  },
  beforeCreate() {
    AmplifyEventBus.$on("authState", (info) => {
      if (info === "signedIn") {
        this.signedIn = true;
        this.$router.push("/mypage");
      }
      if (info === "signedOut") {
        this.$router.push("/auth");
        this.signedIn = false;
      }
    });

    Auth.currentAuthenticatedUser()
      .then(() => {
        this.signedIn = true;
      })
      .catch((err) => {
        console.log(err);
        this.signedIn = false;
        if (this.$route.path !== "/auth") {
          this.$router.push("/auth");
        }
      });
  },
  methods: {
    async toSignOut() {
      Auth.signOut()
        .then(() => {
          this.signedIn = false;
          this.$router.push("/auth");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
