<template>
    <div id="forgotpassword">
        <div class="notification is-danger" v-if="err_response">{{ err_response }}</div>
        <div class="notification is-success" v-if="info_message">{{ info_message }}</div>

        <div v-show="formState === 'forgotPassword'">
            <h1 class="title">パスワードリセット</h1>

            <!-- email -->
            <b-field
                    :message="errors.first('username')"
                    :type="{'is-danger': errors.has('username')}"
                    label="Email"
            >
                <b-input
                        data-vv-as="Email"
                        icon="email-outline"
                        name="username"
                        type="text"
                        v-model="form.username"
                        v-validate="'required|email'"
                ></b-input>
            </b-field>

            <div class="field">
                <div class="control">
                    <button
                            :disabled="errors.any() || !form.username"
                            class="button is-success"
                            v-on:click="forgotPassword"
                    >Send Mail
                    </button>
                </div>
            </div>
        </div>

        <div v-show="formState === 'forgotPasswordSubmit'">
            <h1 class="title">検証コード入力</h1>

            <!-- code -->
            <b-field
                    :message="errors.first('code')"
                    :type="{'is-danger': errors.has('code')}"
                    label="検証コード"
            >
                <b-input
                        data-vv-as="検証コード"
                        name="code"
                        type="text"
                        v-model="form.code"
                        v-validate="'required|numeric|min:6|max:6'"
                ></b-input>
            </b-field>
            <!-- newpassword -->
            <b-field
                    :message="errors.first('newpassword')"
                    :type="{'is-danger': errors.has('newpassword')}"
                    label="New Password"
            >
                <b-input
                        data-vv-as="Password"
                        icon="lock"
                        name="newpassword"
                        type="password"
                        v-model="form.newpassword"
                        v-validate="'required|alpha_num|min:8'"
                ></b-input>
            </b-field>

            <div class="field">
                <div class="control">
                    <button
                            :disabled="errors.any()"
                            class="button is-success"
                            v-on:click="forgotPasswordSubmit"
                    >confirm
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Auth} from "aws-amplify";
    import {AmplifyEventBus} from "aws-amplify-vue";

    export default {
        name: "forgot-password",
        data() {
            return {
                formState: "forgotPassword",
                form: {
                    username: "",
                    code: "",
                    newpassword: ""
                },
                err_response: "",
                info_message: ""
            };
        },
        methods: {
            async forgotPassword() {
                const {username} = this.form;
                Auth.forgotPassword(username)
                    .then(() => {
                        this.formState = "forgotPasswordSubmit";
                    })
                    .catch(err => {
                        if (
                            err.code === "UserNotFoundException" ||
                            err.code === "InvalidParameterException"
                        ) {
                            this.err_response =
                                "入力されたメールアドレスのユーザが見つかりませんでした。ご確認をお願いします。";
                        }
                    });
            },
            async forgotPasswordSubmit() {
                const {username, code, newpassword} = this.form;
                Auth.forgotPasswordSubmit(username, code, newpassword)
                    .then(() => {
                        alert("パスワードリセットが完了しました！"); // TODO: Bulmaのalert dialogを使う
                        // 続けてサインイン
                        Auth.signIn(username, newpassword).then(() => {
                            AmplifyEventBus.$emit("authState", "signedIn");
                            this.$router.push("/mypage");
                        });
                    })
                    .catch(err => {
                        if (err.code === "InvalidPasswordException") {
                            // "Password does not conform to policy: Password must have lowercase characters."
                            this.err_response =
                                "パスワードポリシーを満たしていません。半角英数字、大文字、小文字を一つ以上含み、8文字以上で入力してください。";
                        }
                        if (err.code === "CodeMismatchException") {
                            // "Invalid verification code provided, please try again."
                            this.err_response =
                                "入力された検証コードが間違っています。再入力をお願いします。";
                        }
                    });
            }
        }
    };
</script>