<template>
  <div id="sign-in">
    <div class="container">
      <div v-show="err_response" class="notification is-danger">{{ err_response }}</div>
      <h1 class="title">ログイン</h1>

      <!-- email -->
      <b-field
          :message="errors.first('username')"
          :type="{'is-danger': errors.has('username')}"
          label="Email"
      >
        <b-input
            v-model="form.username"
            v-validate="'required|email'"
            data-vv-as="Email"
            icon="email-outline"
            name="username"
            type="text"
        ></b-input>
      </b-field>

      <!-- password -->
      <b-field
          :message="errors.first('password')"
          :type="{'is-danger': errors.has('password')}"
          label="Password"
      >
        <b-input
            v-model="form.password"
            v-validate="'required'"
            data-vv-as="Password"
            icon="lock"
            name="password"
            type="password"
        ></b-input>
      </b-field>

      <div class="field">
        <div class="control">
          <button :disabled="errors.any() || !form.username || !form.password" class="button is-success"
                  v-on:click="signIn">Login
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Auth} from "aws-amplify";
import {AmplifyEventBus} from "aws-amplify-vue";

export default {
  name: "sign-in",
  data() {
    return {
      form: {
        username: "",
        password: ""
      },
      err_response: ""
    };
  },
  methods: {
    async signIn() {
      const {username, password} = this.form;
      Auth.signIn(username, password)
          .then(data => {
            if (data.isDevelop) {
              console.log(data);
            }
            AmplifyEventBus.$emit("authState", "signedIn");
            if (this.$route.path !== '/mypage') {
              this.$router.push("/mypage");
            }
          })
          .catch(err => {
            if (
                err.code === "NotAuthorizedException" ||
                err.code === "UserNotFoundException" ||
                err.code === "UnexpectedLambdaException"
            ) {
              // "Incorrect username or password."
              // ユーザが見つからなかった場合も、同じエラーとしている
              this.err_response =
                  "メールアドレスもしくはパスワードが間違っています。";
            }
          });
    }
  }
};
</script>