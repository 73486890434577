<template>
    <div id="download">
        <div class="container">
            <div class="notification is-danger" v-show="err_response">{{ err_response }}</div>

            <h1 class="title">ダウンロード</h1>
            <button class="button is-loading" v-show="loading">Downloading......</button>
            <button
                    @click="download"
                    class="button is-text"
                    v-show="!loading"
            >こちらをクリックしてファイルをダウンロードしてください。
            </button>
        </div>
    </div>
</template>

<script>
    import {Storage} from "aws-amplify";
    import axios from "axios";
    // Axios経由(XHR)でダウンロードする方法を当初選択していたが、通常のファイルダウンロードに比べて
    // 経過がわかりづらいため通常のwindow.openに変更した。ただしこちらはポップアップブロッカーを回避して
    // 画面表示と同じタイミングでダウンロードできるメリットもあるため、プログレスバーを作り込む気力があれば
    // こちらでも良いかもしれない。参考: https://tkkm.tokyo/post-177/
    // import Downloader from "../utils/Downloader.js";

    export default {
        name: "download",
        data() {
            return {
                loading: false,
                err_response: ""
            };
        },
        props: {
            filename: String
        },
        methods: {
            download: function () {
                console.log("downloading........");
                this.loading = true;

                // 例: s3://{bucket}/private/{identityId}/download/test00.zip をダウンロードしたい場合
                //   http://localhost:8080/download?filename=download/test00.zip
                // ログインしてない場合はそもそも叩けない
                const s3_key = this.filename;
                Storage.get(s3_key, {
                    level: "private",
                    expires: 30
                }).then(oneTimeUrl => {
                    // HEADリクエスト相当でファイルが存在するかをcheck
                    // (S3はHEADリクエストが403にされてしまうのでRange=0byteのGETで代替)
                    axios({
                        url: oneTimeUrl,
                        method: "GET",
                        headers: {
                            Range: "bytes=0-0"
                        }
                    })
                        .then(() => {
                            window.open(oneTimeUrl, "_blank");
                            this.loading = false;
                        })
                        .catch(err => {
                            if (err.response.status == 404) {
                                this.err_response = "指定されたファイルは存在しませんでした。";
                            }
                            this.loading = false;
                        });
                });
            }
        }
    };
</script>